
import Banner from './components/banner';
import Header from './components/header';
import Layout from './components/layout';
import Footer from './components/footer';
import Store from './components/store';
import Bottom from './components/bottom';

import './App.css';


function App() {
  return (
    <>
      <Banner />
      <div className='app--container'>
        <Header />
        <Layout />
      </div>
      <Store />
      <div className='app--container'>
        <Footer />
      </div>
      <Bottom />
    </>
  );
}

export default App;
