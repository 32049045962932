import React from 'react';
import { HashLink } from 'react-router-hash-link';

import '../assets/css/style.css';
import '../assets/css/media/mobile.css';
import '../assets/css/media/tablet.css';
import '../assets/css/media/desktop.css';

import Profile from '../assets/images/cover/PF08.jpeg';


function Header () {

    return (

        <header className='app--header'>
            <Info />
            <Menu />
        </header>

    );

}

function Info () {

    return (
        <>
            <div className='user--info'>
                <div className='user--image'>
                    <img src={ Profile } alt='user--img' />
                </div>
                <div className='user--details'>
                    <h3> Manna Barua </h3>
                    <h4> On a journey towards Full-Stack Developer </h4>
                    <h5> Actively looking for new opportunity in Finland </h5>
                    <h4> Former Radio Access Network (RAN) Engineer at Huawei </h4>
                    <h4> CCNA R&S || CCNA Cyber Ops || OCA 10g </h4>
                </div>
            </div>
            <div className='social--icons'>
                <a href = "https://www.facebook.com/manna.barua.2015" target="_blank" rel="noreferrer">
                    <i className='bi bi-facebook'></i>
                </a>
                <a href = "https://linkedin.com/" target="_blank" rel="noreferrer">
                    <i className='bi bi-linkedin'></i>
                </a>
                <a href = "https://github.com/mrsparrowfi/myprofile" target="_blank" rel="noreferrer">
                    <i className='bi bi-github'></i>
                </a>
                <a href = "https://twitter.com/" target="_blank" rel="noreferrer">
                    <i className='bi bi-twitter'></i>
                </a>
                <a href = "https://instagram.com/" target="_blank" rel="noreferrer">
                    <i className='bi bi-instagram'></i>
                </a>
            </div>
        </>
    );

}

function Menu () {

    const itemName = [
        'Objective',
        'Bio',
        'Skill',
        'Experience',
        'Education',
        'Certification',
        'Achievement',
        'Language',
        'Contact',
        'Archive',
        'My Shop',
        'My Blog'
    ];

    const menuItems = itemName.map ((item, index) =>
        <li className='menu--item'>
            <HashLink smooth to = { `#${ item }` } key={ index }>
                <span> { item } </span>
            </HashLink>
        </li>
    );

    return (
        <ul className='menu--holder'>
            { menuItems }
        </ul>
    );

}

export default Header;
