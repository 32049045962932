
import IMGF01 from '../assets/images/archive/F01.jpg';
import IMGF02 from '../assets/images/archive/F02.jpg';
import IMGF03 from '../assets/images/archive/F03.jpeg';

import IMGC04 from '../assets/images/archive/C04.jpg';
import IMGC05 from '../assets/images/archive/C05.jpg';

import IMGP01 from '../assets/images/archive/P01.jpg';
import IMGP02 from '../assets/images/archive/P02.jpg';
import IMGP03 from '../assets/images/archive/P03.jpg';
import IMGP04 from '../assets/images/archive/P04.jpg';

import IMGO01 from '../assets/images/archive/O01.jpg';
import IMGO02 from '../assets/images/archive/O02.jpg';
import IMGO03 from '../assets/images/archive/O03.jpg';
import IMGO04 from '../assets/images/archive/O04.jpg';
import IMGO07 from '../assets/images/archive/O07.jpg';
import IMGO08 from '../assets/images/archive/O08.jpg';
import IMGO09 from '../assets/images/archive/O09.jpg';


const images = [

    {
        id : "image--f01",
        source : `${ IMGF01 }`,
        title : "Finland Diary : My first ever snow fall at Lappeenranta"
    },
    {
        id : "image--f02",
        source : `${ IMGF02 }`,
        title : "Finland Diary : My first ever snow fall at Lappeenranta"
    },
    {
        id : "image--f03",
        source : `${ IMGF03 }`,
        title : "Finland Diary : Extreme Winter || Frozen lake Saimaa"
    },
    {
        id : "image--c04",
        source : `${ IMGC04 }`,
        title : "CUET Convocation and Reunion"
    },
    {
        id : "image--c05",
        source : `${ IMGC05 }`,
        title : "CUET Convocation and Reunion"
    },
    {
        id : "image--p01",
        source : `${ IMGP01 }`,
        title : "Huawei AMS 3G Team"
    },
    {
        id : "image--p02",
        source : `${ IMGP02 }`,
        title : "Huawei-Grameen Phone 3G Working Team"
    },
    {
        id : "image--p03",
        source : `${ IMGP03 }`,
        title : "Photo session at work"
    },
    {
        id : "image--p04",
        source : `${ IMGP04 }`,
        title : "Office Party : Project Completion Celebration"
    },
    {
        id : "image--o01",
        source : `${ IMGO01 }`,
        title : "Old Buddies : School Re-union"
    },
    {
        id : "image--o02",
        source : `${ IMGO02 }`,
        title : "Best Friend's Wedding"
    },
    {
        id : "image--o03",
        source : `${ IMGO03 }`,
        title : "Travel Diary : Stone seating || at gun point || India-Bangladesh Border"
    },
    {
        id : "image--o04",
        source : `${ IMGO04 }`,
        title : "Off-time with colleagues"
    },
    {
        id : "image--o07",
        source : `${ IMGO07 }`,
        title : "175 years celebration of my school"
    },
    {
        id : "image--o08",
        source : `${ IMGO08 }`,
        title : "Travel Diary : Tajmahal near Dhaka"
    },
    {
        id : "image--o09",
        source : `${ IMGO09 }`,
        title : "with Nobel laureate for Peace (2006) Professor Dr. Yunus"
    }

]

export function Gallery () {
    return images;
}
