import React from 'react';

import { Header } from './modules';
import Slider from './slider';

import '../assets/css/style.css';
import '../assets/css/media/mobile.css';
import '../assets/css/media/tablet.css';
import '../assets/css/media/desktop.css';


function Archive () {

    return (
        <>
            <Header title = 'Welcome to My Archive' id = 'Archive' />
            <div className='layout--footer'>
                <Slider />
            </div>
        </>
    );

}

export default Archive;
