import React from 'react';

import '../assets/css/style.css';
import '../assets/css/media/mobile.css';
import '../assets/css/media/tablet.css';
import '../assets/css/media/desktop.css';

import '../assets/css/store.css';
import S01 from '../assets/images/store/S01.jpg';
import S02 from '../assets/images/store/S02.jpg';
import S03 from '../assets/images/store/S03.jpg';

import S04 from '../assets/images/store/S04.jpg';
import S05 from '../assets/images/store/S05.jpg';
import S06 from '../assets/images/store/S06.jpg';

import S07 from '../assets/images/store/S07.jpg';
import S08 from '../assets/images/store/S08.jpg';
import S09 from '../assets/images/store/S09.jpg';


function Store () {

    return (
        <div className='store--container'>
            <Header title = 'Browse My Online Store' id = 'My Shop' />
            <div className='store--footer'>
                <div className='store--card--holder'>
                    <div className='flex--space'></div>
                    <StoreCard title = "Backpack" imgSRC = { S01 } category = "Accessories" url = "https://www.oriyostyles.com/accessories" />
                    <StoreCard title = "Black Mug" imgSRC = { S02 } category = "Drinkware" url = "https://www.oriyostyles.com/Drinkware" />
                    <StoreCard title = "Hoodies" imgSRC = { S03 } category = "Apparels" url = "https://www.oriyostyles.com/apparel" />
                    <StoreCard title = "Bike Short" imgSRC = { S04 } category = "Women's Sports" url = "https://www.oriyostyles.com/apparel" />
                    <StoreCard title = "Phone Cases" imgSRC = { S05 } category = "Tech Accessories" url = "https://www.oriyostyles.com/accessories" />
                    <div className='flex--space'></div>
                </div>
                <div className='store--card--holder small--screen'>
                    <div className='flex--space'></div>
                    <StoreCard title = "Indoor Pillow" imgSRC = { S06 } category = "Home & Decor" url = "https://www.oriyostyles.com/homeware" />
                    <StoreCard title = "Long Sleeve Tee" imgSRC = { S07 } category = "Apparels" url = "https://www.oriyostyles.com/apparel" />
                    <StoreCard title = "Black Slides" imgSRC = { S08 } category = "Accessories" url = "https://www.oriyostyles.com/accessories" />
                    <StoreCard title = "Classic Tote Bag" imgSRC = { S09 } category = "Accessories" url = "https://www.oriyostyles.com/accessories" />
                    <div className='flex--space'></div>
                </div>
            </div>
            <div className='store--icons'>
                <div className='icon--space'></div>
                <div className='icon--title'> Follow us : </div>
                <a href = "https://www.facebook.com/profile.php?id=100095031821821" target="_blank" rel="noreferrer">
                    <i className='bi bi-facebook'></i>
                </a>
                <a href = "https://www.tiktok.com/@oriyostyles" target="_blank" rel="noreferrer">
                    <i className='bi bi-tiktok'></i>
                </a>
                <a href = "https://twitter.com/oriyostyles" target="_blank" rel="noreferrer">
                    <i className='bi bi-twitter'></i>
                </a>
                <a href = "https://www.instagram.com/oriyostyle/" target="_blank" rel="noreferrer">
                    <i className='bi bi-instagram'></i>
                </a>
                <div className='icon--space'></div>
            </div>
        </div>
    );

    function Header (props) {

        return (
            <div className='store--header' id = { props.id }>
                <h3> { props.title } </h3>
            </div>
        );
    
    }

    function StoreCard (props) {

        return (
            <div className="store--card" >

                <section className="scard--header">
                    <span className="scard--title"> { props.title } </span>
                    <span className="card--percentage"> -10% </span>
                </section>

                <section className="card--image">
                    <img src={ props.imgSRC } alt="card_image" />
                </section>

                <section className="card--body">
                    <span className="item--category"> { props.category } </span>
                </section>

                <section className="card--body">
                    <span className="item--available"> Use code "ORIYO" at checkout </span>
                </section>

                <section className="card--footer">
                    <a href = { props.url } target="_blank" rel="noreferrer">
                        <button className="card--btn" type="submit"> Browse Store </button>
                    </a>
                </section>

            </div>
        );
    }

}

export default Store;
