import React from 'react';
import '../assets/css/banner.css';


function Banner () {

    return (
        <ul className='bubbles'>
            <li className='bubble--bottom'>M</li>
            <li className='bubble--bottom'>@</li>
            <li className='bubble--bottom'>A</li>
            <li className='bubble--bottom'>€</li>
            <li className='bubble--bottom'>N</li>
            <li className='bubble--bottom'>£</li>
            <li className='bubble--bottom'>N</li>
            <li className='bubble--bottom'>µ</li>
            <li className='bubble--bottom'>A</li>
            <li className='bubble--bottom'>&</li>
            <li className='bubble--top'>0</li>
            <li className='bubble--top'>r</li>
            <li className='bubble--top'>i</li>
            <li className='bubble--top'>y</li>
            <li className='bubble--top'>0</li>
            <li className='bubble--top'>s</li>
            <li className='bubble--top'>0</li>
            <li className='bubble--top'>f</li>
            <li className='bubble--top'>t</li>
            <li className='bubble--top'>%</li>
            <ul className="title">
                <li className='flex--space'></li>
                <li className="title-word title-word-1">Maturity</li>
                <li className='flex--space'></li>
                <li className="title-word title-word-2">Starts</li>
                <li className='flex--space'></li>
                <li className="title-word title-word-3">When</li>
                <li className='flex--space'></li>
                <li className="title-word title-word-4">We</li>
                <li className='flex--space'></li>
                <li className="title-word title-word-5">Respect</li>
                <li className='flex--space'></li>
                <li className="title-word title-word-6">Differences</li>
                <li className='flex--space'></li>
            </ul>
        </ul>
    );

}

export default Banner;
