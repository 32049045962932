import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Header } from './modules';

import '../assets/css/style.css';
import '../assets/css/media/mobile.css';
import '../assets/css/media/tablet.css';
import '../assets/css/media/desktop.css';


function Network () {

    return (
        <>
            <Header title = 'My Network' id = 'network' />
            <div className='layout--footer'>
                <GuestCard />
            </div>
        </>
    );

}

function GuestCard () {

    const guestsImgUrl = "https://th.bing.com/th/id/OIP.PZaM2TzeWG3wTRBI37kmBAHaHr?pid=ImgDet&w=846&h=878&rs=1";
    const [ guestList, setGuestList ] = useState([]);
    const [ open, setOpen ] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const fetchData = useCallback(() => {
        axios({
            "method" : "GET",
            "url" : "/guests/listAllGuests",
            "headers" : {},
            "params" : {}
        })
        .then((response) => {
            setGuestList(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    return (
        <>
            <div className='card--holder'>
                {
                    guestList.slice(0, 4).map((item, index) => {
                        return (
                            <ul className='guest--card' key={ index }>
                                <li className='card--header'>
                                    {
                                        ( item.imageUrl === "" ) ? 
                                        <img src={ guestsImgUrl } alt='guest--img' /> : 
                                        <img src={ item.imageUrl } alt='guest--img' />
                                    }
                                    <ul className='guest--info'>

                                        <h3 className='guest--name'> { item.fullname } </h3>
                                        <h4 className='guest--position'> { item.position } </h4>
                                        <h5 className='guest--company'> { item.company } </h5>

                                        <li className='social--icons'>
                                            <Link to = {{ pathname : `${ item.facebookUrl }` }} target='_blank'>
                                                <i className='bi bi-facebook'></i>
                                            </Link>
                                            <Link to = {{ pathname : `${ item.linkedinUrl }` }} target='_blank'>
                                                <i className='bi bi-linkedin'></i>
                                            </Link>
                                            <Link to = {{ pathname : `${ item.twitterUrl }` }} target='_blank'>
                                                <i className='bi bi-twitter'></i>
                                            </Link>
                                            <Link to = {{ pathname : `${ item.instagramUrl }` }} target='_blank'>
                                                <i className='bi bi-instagram'></i>
                                            </Link>
                                            <Link to = {{ pathname : `${ item.githubUrl }` }} target='_blank'>
                                                <i className='bi bi-github'></i>
                                            </Link>
                                        </li>

                                    </ul>
                                </li>
                                <li className='guest--notes'>
                                    <p> { item.comments } </p>
                                </li>
                            </ul>
                        );
                    })
                }

                {
                    open && ( guestList.slice(4).map((item, index) => {
                        return (
                            <ul className='guest--card' key={ index }>
                                <li className='card--header'>
                                    {
                                        ( item.imageUrl === "" ) ? 
                                        <img src={ guestsImgUrl } alt='guest--img' /> : 
                                        <img src={ item.imageUrl } alt='guest--img' />
                                    }
                                    <ul className='guest--info'>

                                        <h3 className='guest--name'> { item.fullname } </h3>
                                        <h4 className='guest--position'> { item.position } </h4>
                                        <h5 className='guest--company'> { item.company } </h5>

                                        <li className='social--icons'>
                                            <Link to = {{ pathname : `${ item.facebookUrl }` }} target='_blank'>
                                                <i className='bi bi-facebook'></i>
                                            </Link>
                                            <Link to = {{ pathname : `${ item.linkedinUrl }` }} target='_blank'>
                                                <i className='bi bi-linkedin'></i>
                                            </Link>
                                            <Link to = {{ pathname : `${ item.twitterUrl }` }} target='_blank'>
                                                <i className='bi bi-twitter'></i>
                                            </Link>
                                            <Link to = {{ pathname : `${ item.instagramUrl }` }} target='_blank'>
                                                <i className='bi bi-instagram'></i>
                                            </Link>
                                            <Link to = {{ pathname : `${ item.githubUrl }` }} target='_blank'>
                                                <i className='bi bi-github'></i>
                                            </Link>
                                        </li>

                                    </ul>
                                </li>
                                <li className='guest--notes'>
                                    <p> { item.comments } </p>
                                </li>
                            </ul>
                        );

                    }
                    ))
                }
            </div>
            {
                guestList.length > 4 ? 
                <p className='form--btn'>
                    <button onClick= { toggle } className='btn'>
                        {
                            open ? <span> Close 
                                <i className="bi bi-x-square"></i>
                            </span> : 
                            <span> More Recommendations 
                                <i className="bi bi-chevron-compact-right"></i>
                            </span>
                        }
                    </button>
                </p> : null
            }
        </>
    );

}

export default Network;
