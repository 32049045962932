import React, { useState } from 'react';
import axios from 'axios';

import { Header } from "./modules";

import '../assets/css/style.css';
import '../assets/css/media/mobile.css';
import '../assets/css/media/tablet.css';
import '../assets/css/media/desktop.css';


function Form () {

    const [ fullname, setFullname ] = useState('');
    const [ position, setPosition ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ comments, setComments ] = useState('');

    const [ imageUrl, setImageUrl ] = useState('');
    const [ facebookUrl, setFacebookUrl ] = useState('');
    const [ twitterUrl, setTwitterUrl ] = useState('');
    const [ linkedinUrl, setLinkedinUrl ] = useState('');
    const [ instagramUrl, setInstagramUrl ] = useState('');
    const [ githubUrl, setGithubUrl ] = useState('');

    const insertGuests = () => {
        axios.post('/guests/insertGuests', {
            fullname : fullname,
            position : position,
            company : company,
            comments : comments,
            imageUrl : imageUrl,
            facebookUrl : facebookUrl,
            twitterUrl : twitterUrl,
            linkedinUrl : linkedinUrl,
            instagramUrl : instagramUrl,
            githubUrl : githubUrl
        });

    }


    return (
        <>
            <Header title = "I value your recommendation" id = "form" />
            <form className="layout--footer btn--space" onSubmit = { insertGuests }>
                <div className="layout--form">
                    <ul className="right--panel">
                        <li>
                            <h5> * Full Name: </h5>
                            <input 
                                type="text" 
                                id="fullname" 
                                name='fullname' 
                                value={ fullname } 
                                placeholder="Elon Musk" 
                                onChange={ (e) => { setFullname(e.target.value) }} 
                                required 
                            />
                        </li>
                        <li>
                            <h5> * Position: </h5>
                            <input 
                                type="text" 
                                id="position" 
                                name='position' 
                                value={ position } 
                                placeholder="Founder, CEO & Chief Engineer" 
                                onChange={ (e) => { setPosition(e.target.value) }} 
                                required 
                            />
                        </li>
                        <li>
                            <h5> * Company: </h5>
                            <input 
                                type="text" 
                                id="company" 
                                name='company' 
                                value={ company } 
                                placeholder="www.spacex.com" 
                                onChange={ (e) => { setCompany(e.target.value) }} 
                                required 
                            />
                        </li>
                        <li>
                            <h5> * Write about your experience: </h5>
                            <textarea 
                                type="text" 
                                id="details" 
                                name='comments' 
                                value={ comments } 
                                placeholder=" I wish him all the great success that he deserves. " 
                                onChange={ (e) => { setComments(e.target.value) }} 
                                required 
                            ></textarea>
                        </li>
                    </ul>

                    <ul className="left--panel">
                        <li>
                            <h5> # Picture(Optional): </h5>
                            <input 
                                type="text" 
                                id="imageUrl" 
                                name='imageUrl' 
                                value={ imageUrl } 
                                placeholder="https://www.facebook.com" 
                                onChange={ (e) => { setImageUrl(e.target.value) }} 
                            />
                        </li>
                        <li>
                            <h5> # Facebook(Optional): </h5>
                            <input 
                                type="text" 
                                id="facebookUrl" 
                                name='facebookUrl' 
                                value={ facebookUrl } 
                                placeholder="https://www.facebook.com" 
                                onChange={ (e) => { setFacebookUrl(e.target.value) }} 
                            />
                        </li>
                        <li>
                            <h5> # Twitter(Optional): </h5>
                            <input 
                                type="text" 
                                id="twitterUrl" 
                                name='twitterUrl' 
                                value={ twitterUrl } 
                                placeholder="https://www.twitter.com" 
                                onChange={ (e) => { setTwitterUrl(e.target.value) }} 
                            />
                        </li>
                        <li>
                            <h5> # LinkedIn(Optional): </h5>
                            <input 
                                type="text" 
                                id="linkedinUrl" 
                                name='linkedinUrl' 
                                value={ linkedinUrl } 
                                placeholder="https://www.linkedin.com" 
                                onChange={ (e) => { setLinkedinUrl(e.target.value) }} 
                            />
                        </li>
                        <li>
                            <h5> # Instagram(Optional): </h5>
                            <input 
                                type="text" 
                                id="instagramUrl" 
                                name='instagramUrl' 
                                value={ instagramUrl } 
                                placeholder="https://www.instagram.com" 
                                onChange={ (e) => { setInstagramUrl(e.target.value) }} 
                            />
                        </li>
                        <li>
                            <h5> # Github(Optional): </h5>
                            <input 
                                type="text" 
                                id="githubUrl" 
                                name='githubUrl' 
                                value={ githubUrl } 
                                placeholder="https://www.github.com" 
                                onChange={ (e) => { setGithubUrl(e.target.value) }} 
                            />
                        </li>
                    </ul>
                </div>
                <p className='form--btn'>
                    <button 
                        className='btn' 
                        type='submit' 
                        value='Insert Guests'  
                    > Recommand Me </button>
                </p>
            </form>
        </>
    );

}

export default Form;
