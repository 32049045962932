import React, { useState, useEffect, useRef } from "react";
import { Gallery } from "./gallery";

import "../assets/css/slider.css";


function Slider () {

    const delay = 3000;
    const gallery = Gallery();
    const len = (window.innerWidth <= 850) ? gallery.length : (gallery.length) / 2;

    const timeoutRef = useRef(null);
    const [ index, setIndex ] = useState(0);
    const [ paused, setPaused ] = useState(false);

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = len - 1;
        } else if (newIndex >= len) {
            newIndex = 0;
        }
        setIndex(newIndex);
    };

    function resetTimeout () {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {

        resetTimeout ();
        timeoutRef.current = setTimeout(() => 
            {
                if(!paused) {
                    setIndex((prevIndex) =>
                        (prevIndex === (len - 1)) ? 0 : (prevIndex + 1)
                    )
                    updateIndex(index + 1);
                }
            }, delay
        );

        return () => {
            resetTimeout ();
        };

    });


    return (

        <section className="slider--container" id="slider--container">

            <button 
                className="slider--btn btn--left"
                onClick = { () => {
                    updateIndex(index - 1);
                }}
            >
                <i className="bi bi-chevron-compact-left"></i>
            </button>

            <div
                className="slider--controller"
                style = {{ transform: `translateX(${ - index * (100)}%)` }}
            >
                {
                    gallery.map((item, index) => (
                        <div
                            className="slide--item"
                            key={ index }
                            onMouseEnter = { () => setPaused(true) }
                            onMouseLeave = { () => setPaused(false) }
                        >
                            <ul id={ item.id } className="image--frame">
                                <li>
                                    <img src={ item.source } alt="archive--img" />
                                </li>
                                <li className="caption">
                                    <span> { item.title } </span>
                                </li>
                            </ul>
                        </div>
                    ))
                }
            </div>

            <button 
                className="slider--btn btn--right"
                onClick = { () => {
                    updateIndex( index + 1 );
                }}
            >
                <i className="bi bi-chevron-compact-right"></i>
            </button>

        </section>

    );

}

export default Slider;
